import * as React from "react";
import { useEffect } from "react"; // Import useEffect
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppAppBar from "./components/AppAppBar";
import MainContent from "./components/MainContent";
import Latest from "./components/Latest";
import Footer from "./components/Footer";
import getBlogTheme from "./theme/getBlogTheme";
import ReactGA from "react-ga"; // Import ReactGA

export default function Blog() {
  const [mode] = React.useState("dark");
  const [showCustomTheme] = React.useState(true);
  const blogTheme = createTheme(getBlogTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  // Google Analytics Initialization
  const trackingId = "G-4JYKTFGMBD"; // Your Measurement ID
  ReactGA.initialize(trackingId);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search); // Track page view on component mount
  }, []); // Empty dependency array to run only on mount

  return (
    <ThemeProvider theme={showCustomTheme ? blogTheme : defaultTheme}>
      <CssBaseline enableColorScheme />
      <AppAppBar />
      <Container
        maxWidth="lg"
        component="main"
        sx={{ display: "flex", flexDirection: "column", my: 16, gap: 4 }}
      >
        <MainContent />
        <Latest />
      </Container>
      <Footer />
    </ThemeProvider>
  );
}
